html {
  background-color: #0a2133;
}

body {
  overflow: hidden;
  margin: 0;
}

@font-face {
  font-family: 'sharpGroteskBook19';
  src: url('./assets/fonts/SharpGroteskBook19.woff');
}

@font-face {
  font-family: 'sharpGroteskBook25';
  src: url('./assets/fonts/SharpGroteskBook25.woff');
}

@font-face {
  font-family: 'SharpGroteskLight05';
  src: url('./assets/fonts/SharpGroteskLight05.woff');
}

@font-face {
  font-family: 'sharpGroteskLight09';
  src: url('./assets/fonts/SharpGroteskLight09.woff');
}

@font-face {
  font-family: 'sharpGroteskLight25';
  src: url('./assets/fonts/SharpGroteskLight25.woff');
}

@font-face {
  font-family: 'sharpGroteskMedium25';
  src: url('./assets/fonts/SharpGroteskMedium25.woff');
}
